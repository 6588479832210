/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { Button, Container } from "@material-ui/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
// import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import { getAllEmployee } from "../../services/apiservices/employee";
import SingleSelect from "../../components/SelectField/SingleSelect";
import { getAllAppoinment } from "../../services/apiservices/appoinments";
import { getClientsList } from "../../services/apiservices/client";
import { useNavigate } from "react-router";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import AppoinmentSchedule from "./AppoinmentSchedule";

function getTaskTitles(clientData) {
    const taskTitles = [];
    for (const shiftId in clientData.shiftData) {
        const shift = clientData.shiftData[shiftId];
        for (const taskId in shift) {
            const taskList = shift[taskId].tasksList;
            taskList.forEach(task => taskTitles.push(task.title));
        }
    }
    return taskTitles.join(", ");
}

const AppointmentModal = ({ onClose, selectedAppointment, handelReSchduleClicks, isMobile, isLandscape, clientDetails, employeeDetails, appoinments, setIsShow, isShow }) => {

    const clientId = selectedAppointment?.clientId;
    const clientData = clientDetails?.find(client => client.id === clientId);

    const createdBy = selectedAppointment.scheduledBy;
    const userData = employeeDetails?.data?.find(emp => emp.email === createdBy);

    const inputDate = new Date(selectedAppointment.date);
    const formattedDate = inputDate.toLocaleDateString("en-GB", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric"
    });
    const month = inputDate.toLocaleString('en-US', { month: 'short' });
    const day = inputDate.getDate();
    const getTiltles = getTaskTitles(clientData);

    const findMatchingAppointments = () => {
        if (!selectedAppointment) return [];

        return appoinments.appointments.filter(appointment => {
            return (
                appointment.dateCreated === selectedAppointment.dateCreated &&
                appointment.numberOfTimes === selectedAppointment.numberOfTimes &&
                appointment.frequency === selectedAppointment.frequency &&
                appointment.clientId === selectedAppointment.clientId &&
                appointment.calendar === selectedAppointment.calendar &&
                appointment.scheduledBy === selectedAppointment.scheduledBy
            );
        });
    };

    const matchingAppointments = findMatchingAppointments();

    return (
        <>
            <div className="modal-overlay active">
                <div className="modal-content">
                <Container>
                    <div className="d-flex flex-space-between flex-center">
                        <div className="flex-start"><Button className="modal-close link" onClick={onClose}>Close</Button></div>
                        <div><Button variant="contained" style={{ backgroundColor: "green", color: "white" }} onClick={() => setIsShow(true)}>ReSchedule</Button></div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '1px',
                            backgroundColor: '#ddd',
                        }} className="mb-20 mt-10"
                    ></div>

                    <div>
                        <div className="details-grid">
                            <div className="details-column">
                                <div className="appointment-header">
                                    <div class="date-icon">
                                        <div class="date-icon-top-lines">
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                        </div>
                                        <div class="date-icon-day">{day}</div>
                                        <div class="date-icon-month">{month}</div>
                                    </div>

                                    <div>
                                        <h2 className="appointment-title">{clientData?.firstName} {clientData?.lastName}</h2>
                                        <div className="appointment-datetime">
                                            {formattedDate}<br />
                                            <span style={{ color: "#484848", fontWeight: "600" }}>{selectedAppointment?.time} - {selectedAppointment?.endTime}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${isMobile || isLandscape ? "d-flex flex-direction-column" : "d-flex"}`}>
                                    <div className="detail-item">
                                        <div className="detail-label">Phone</div>
                                        <div className="detail-value">{clientData?.contact[0]?.mobileNumber}</div>
                                    </div>
                                    <div className={`${isMobile || isLandscape ? "detail-item" : "detail-item ml-25"}`}>
                                        <div className="detail-label">Email</div>
                                        <div className="detail-value">{clientData?.contact[0]?.email}</div>
                                    </div>
                                    <div className={`${isMobile || isLandscape ? "detail-item" : "detail-item ml-25"}`}>
                                        <div className="detail-label">Client's Time Zone</div>
                                        <div className="detail-value">{clientData?.timezone}</div>
                                    </div>
                                    <div className={`${isMobile || isLandscape ? "detail-item" : "detail-item ml-25"}`}>
                                        <div className="detail-label">Client Location</div>
                                        <div className="detail-value">{selectedAppointment?.ClientLocation}</div>
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label">Client Address</div>
                                    <div className="detail-value">{selectedAppointment?.clientAddress}</div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label">Shift Details</div>
                                    <div className="detail-value">{getTiltles}</div>
                                </div>
                            </div>

                            <div className="details-column right">
                                <div className="d-flex flex-space-between">
                                    <div className="detail-item">
                                        <div className="detail-label2">Appointment Type</div>
                                        <div className="detail-value2">{selectedAppointment?.type}</div>
                                    </div>
                                    <div className="detail-item ml-10" style={{ width: "50%" }} >
                                        <div className="detail-label2">Employee Name</div>
                                        <div className="detail-value2">{selectedAppointment?.calendar}</div>
                                    </div>
                                </div>
                                <div className="d-flex flex-space-between">
                                    <div className="detail-item">
                                        <div className="detail-label2">Shift Name</div>
                                        <div className="detail-value2">{selectedAppointment?.shiftName}</div>
                                    </div>
                                    <div className="detail-item ml-10" style={{ width: "50%" }} >
                                        <div className="detail-label2">Created By</div>
                                        <div className="detail-value2">{userData?.firstName}{userData?.lastName}</div>
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label2">Appointments</div>
                                    <div className="appointments-list">
                                        {matchingAppointments.map((apt, index) => (
                                            <div key={index} className="appointment-item">
                                                <div className="ml-10"><span style={{ color: "#7d7d7d", fontWeight: "500" }}>{apt?.time} on </span> <span style={{ color: "#3c3c3c", fontSize: "14px", fontWeight: "800" }}>{apt?.date}</span></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Container>
                </div>
                
            </div>
            {isShow ?
                <div className="modal-overlay active">
                    <div className="modal-content2">
                        <div className="d-flex flex-space-between flex-center">
                            <div className="flex-start">
                                <Button className="modal-close link"
                                    onClick={() => {
                                        setIsShow(false);
                                        onClose();
                                    }}>Close</Button></div>
                            <div><Button variant="contained" style={{ backgroundColor: "green", color: "white" }}>ReSchedule</Button></div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                height: '1px',
                                backgroundColor: '#ddd',
                            }} className="mb-20 mt-10"
                        ></div>
                        <AppoinmentSchedule modal={true}/>
                    </div>
                </div> : null}
        </>
    );
};

const AppointmentsDetailComponent = ({ appointments, handleAppointmentOpen}) => {
    return (
        <div>
            {Object.keys(appointments).map((date) => (
                <div key={date} className="mb-30">
                    <div className="d-flex flex-center pl-10" style={{ backgroundColor: "#eef5f9", height: "35px", fontSize: "14px", fontWeight: "500" }}>
                        {date}
                    </div>

                    {appointments[date].map((appointment, index) => (
                        <>
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    marginBottom: '10px',
                                    marginTop: '15px',
                                    position: 'relative',
                                    marginLeft: '20px',
                                    marginRight: "5px",
                                    paddingLeft: "12px"
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        width: '4px',
                                        backgroundColor: '#eef5f9',
                                    }}
                                ></div>

                                <div style={{ flex: 1 }}>
                                    <div className="mv-5" style={{ color: "#078037", fontSize: "14px", fontWeight: "600" }} onClick={() => handleAppointmentOpen(appointment)}>{appointment.calendar}</div>
                                    <div>{appointment.calendarTimezone}</div>
                                    <div className="mt-20" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="mv-5">{appointment.forms[0]?.values[0]?.value} Shift</div>
                                        <div>{appointment.duration} minutes</div></div>
                                    <div className="mb-10">{appointment.time} - {appointment.endTime}</div>
                                </div>
                            </div>
                            {index < appointments[date].length - 1 &&
                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: '#ddd',
                                    }} className="mv-20"
                                ></div>}
                        </>
                    ))}
                </div>
            ))}
        </div>
    );
};

const ManageAppointments = () => {

    const [empNames, setEmpNames] = useState("All");
    const [employeeNameList, setEmployeeNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const [clientNameList, setClientNameList] = useState([]);
    const [clientDetails, setClientDetails] = useState();
    const [employeeDetails, setEmployeeDetails] = useState();
    const [appoinments, setAppoinments] = useState([]);
    let navigate = useNavigate();
    const [selectedAppointment, setSelectedAppointment] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [upcomingAppointments, setUpcomingAppointments] = useState({});
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [currentView, setCurrentView] = useState("dayGridMonth");
    const [filteredAppointment, setFilteredAppointments] = useState([]);
    const [isShow, setIsShow] = useState(false);

    const ManageAppointmentsData = async () => {
        const appointmentResponse = await getAllAppoinment();
        if (appointmentResponse) {
            setAppoinments(appointmentResponse);
            const upcomingAppointments = appointmentResponse.appointments
                .sort((a, b) => {
                    if (a.date !== b.date) return a.date.localeCompare(b.date);
                    return a.time.localeCompare(b.time);
                });

            const groupAppointmentsByDate = upcomingAppointments.reduce((grouped, appointment) => {
                const { date } = appointment;
                if (!grouped[date]) grouped[date] = [];
                grouped[date].push(appointment);
                return grouped;
            }, {});
            setUpcomingAppointments(groupAppointmentsByDate);
            const currentMonth = new Date().getMonth();

            const filtered = Object.keys(groupAppointmentsByDate).filter((date) => {
                const appointmentDate = new Date(date);
                return appointmentDate.getMonth() === currentMonth;
            });

            const filteredAppointments = filtered.reduce((result, date) => {
                result[date] = groupAppointmentsByDate[date];
                return result;
            }, {});

            setFilteredAppointments(filteredAppointments);
        } else {
            setAppoinments([]);
            setUpcomingAppointments({});
            setFilteredAppointments([]);
        }
        await getClientsList({}, (res) => {
            if (res) {
                setClientDetails(res);
                const clientNames = res.map(client => ({
                    value: client?.id,
                    label: client?.clientName,
                    firstName: client?.firstName,
                    lastName: client?.lastName
                }));
                setClientNameList(clientNames);
            }
        })
        await getAllEmployee((res) => {
            setEmployeeDetails(res);
            const employeeNames = res.data?.map((emp) => ({
                value: emp?.id,
                client: emp?.clients,
                label: `${emp?.firstName} ${emp?.lastName}`,
                email: emp?.email,
            }));
            setEmployeeNameList([{ label: "All", value: "All" }, ...employeeNames])
        })
    }

    useEffect(() => {
        ManageAppointmentsData();
    }, [])

    const handleEmpNameChange = (e) => {
        const selectEmployee = e.target.value;
        setEmpNames(selectEmployee);
    };

    const redirectToSchedule = () => {
        navigate("/appoinmentSchedule");
    }

    // const getCalendarEvents = () => {
    //     if (!appoinments || appoinments.length === 0) {
    //         return [];
    //     }
    //     const events = appoinments.appointments

    //         .filter(appointment => empNames === "All" || appointment.userId === empNames)
    //         .map(appointment => {
    //             const client = clientNameList.find(client => client.value === appointment.clientId);
    //             const formattedDate = moment(appointment.date, 'MMMM D, YYYY').format('YYYY-MM-DD');

    //             return {
    //                 title: `  ${client ? client.label : "Unknown Client"}`,
    //                 start: `${formattedDate}T${moment(appointment.time, 'h:mm a').format('HH:mm:ss')}`,
    //                 id: appointment.id,
    //             };
    //         });

    //     return events;
    // };

    const getCalendarEvents = () => {
        if (!appoinments || !appoinments.appointments || appoinments.appointments.length === 0) {
            return [];
        }

        const appointmentsByDate = {};

        appoinments.appointments
            .filter(appointment => empNames === "All" || appointment.userId === empNames)
            .forEach(appointment => {
                const client = clientNameList.find(client => client.value === appointment.clientId);
                const formattedDate = moment(appointment.date, 'MMMM D, YYYY').format('YYYY-MM-DD');
                const timeFormatted = moment(appointment.time, 'h:mm a').format('HH:mm:ss');
                const endtimeFormatted = moment(appointment.endTime, 'h:mm a').format('HH:mm:ss');

                if (!appointmentsByDate[formattedDate]) {
                    appointmentsByDate[formattedDate] = {
                        date: formattedDate,
                        count: 0,
                        events: []
                    };
                }

                appointmentsByDate[formattedDate].count += 1;

                const eventDetails = !isMobile && !isLandscape ? {
                    title: `${client ? client.label : "Unknown Client"}`,
                    start: `${formattedDate}T${timeFormatted}`,
                    end: `${formattedDate}T${endtimeFormatted}`,
                    id: appointment.id,
                    details: appointment,
                } : null;

                appointmentsByDate[formattedDate].events.push(eventDetails);
            });

        const events = Object.values(appointmentsByDate).flatMap(({ date, count, events }) => [
            {
                title: `${count} ${(!isMobile && !isLandscape) ? "Appts" : ""}`,
                start: `${date}T00:00:00`,
                allDay: true,
                display: 'background',
                extendedProps: {
                    appointmentCount: count
                },
            },
            ...events.filter(event => event !== null)
        ]);
        return events;
    };

    const handleEventClick = (clickInfo) => {
        setSelectedAppointment(clickInfo.event.extendedProps.details);
        setIsModalOpen(true);
    };
    const handleViewChange = (view) => {
        setCurrentView(view.type);

        const viewStartDate = new Date(view.view.currentStart);
        const viewEndDate = new Date(view.view.currentEnd);

        const viewMonth = viewStartDate.getMonth();

        const filtered = Object.keys(upcomingAppointments).filter((date) => {
            const appointmentDate = new Date(date);
            return appointmentDate.getMonth() === viewMonth;
        });

        const filteredAppointments = filtered.reduce((result, date) => {
            result[date] = upcomingAppointments[date];
            return result;
        }, {});

        setFilteredAppointments(filteredAppointments);
    };

    const handelReSchduleClicks = () => {
        console.log("asd")
    }

    const handleAppointmentOpen = (appointment) => {
        setIsModalOpen(true);
        setSelectedAppointment(appointment)
    }

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="View Schedule"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Appoinments" },
                    ]}
                    BreadCrumbCurrentPageTitle={"View Schedule"}
                    showLastSeen={true}
                />
                <div className="sectionBox">
                    <div className="d-flex flex-justify-flexend flex-center mb-15">
                        <div style={{ width: isMobile || isLandscape ? "250px" : "300px" }}>
                            <SingleSelect
                                value={empNames}
                                name={"employeeName"}
                                label={"Employee Name"}
                                onChange={handleEmpNameChange}
                                options={employeeNameList}
                                datetime={true}
                            />
                        </div>
                        <div className="ml-20">
                            <Button variant="contained" className={`${isMobile || isLandscape ? "pv-10 ph-10" : "pv-10 ph-20"}`} style={{ backgroundColor: "green", color: "white" }} onClick={redirectToSchedule}>
                                Schedule Now
                            </Button>
                        </div>
                    </div>

                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            left: 'title,prev,next',
                            right: 'timeGridDay,timeGridWeek,dayGridMonth'
                        }}
                        // resources={clientNameList.map(client => ({
                        //     id: client.value,
                        //     title: client.label
                        // }))}
                        events={getCalendarEvents()}
                        eventClick={handleEventClick}
                        viewDidMount={handleViewChange}
                        datesSet={handleViewChange}
                        buttonText={{
                            today: 'Today',
                            month: 'Monthly',
                            week: 'Week',
                            day: 'Day'
                        }}
                        views={{
                            dayGridMonth: {
                                buttonText: "Monthly View"
                            },
                            timeGridWeek: {
                                type: "timeGridWeek",
                                slotMinTime: "07:00:00",
                                slotMaxTime: "20:00:00",
                                buttonText: "Weekly View",
                            },
                            timeGridDay: {
                                type: "timeGridDay",
                                slotMinTime: "07:00:00",
                                slotMaxTime: "20:00:00",
                                buttonText: "Daily View",
                            }
                        }}
                        editable={true}
                        selectable={true}
                        allDaySlot={false}
                        eventTimeFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
                    />

                    <div className="mt-40">
                        <AppointmentsDetailComponent appointments={filteredAppointment} handleAppointmentOpen={handleAppointmentOpen}/>
                    </div>

                    {isModalOpen && selectedAppointment && (
                        <AppointmentModal
                            onClose={() => setIsModalOpen(false)}
                            selectedAppointment={selectedAppointment}
                            handelReSchduleClicks={handelReSchduleClicks}
                            isMobile={isMobile}
                            isLandscape={isLandscape}
                            clientDetails={clientDetails}
                            employeeDetails={employeeDetails}
                            appoinments={appoinments}
                            setIsShow={setIsShow}
                            isShow={isShow}
                        />
                    )}
                </div>

            </Container>
        </>
    );
};

export default ManageAppointments;
